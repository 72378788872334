import { Box, Grid, Typography, useTheme } from "@mui/material";
import Separator from "../../../common/ui/Separator";
import PolicyListCard from "./PolicyListCard";
import { useTranslation } from "react-i18next";
import CustomButton from "../../../common/ui/CustomButton";
import { ReactComponent as Arrow } from '../../../common/icons/right-arrow.svg';
import SvgConverter from "../../../common/SvgConverter";
import AddPolicyModal from "./AddPolicyModals/AddPolicyModal";
import { useEffect, useMemo, useState } from "react";
import AddPolicyViaNumberModal from "./AddPolicyModals/AddPolicyViaNumberModal";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from "../../../common/constants/Routes";
import { useDispatch, useSelector } from "react-redux";
import { addManualPolicies, isLoadingExistingPoliciesFromEIA, isLoadingInsSamadhanUploadDoc, removePolicy, requestExistingPolicies, selectExistingPoliciesFromEIA, selectNewManualPolicies } from "./addPolicy.slice";
import { groupPoliciesByALCode } from "../../../common/utils";
import Spinner from "../../../common/ui/Spinner";
import ApiModal from "../../../common/ui/Modal/ApiModal";
import UploadModal from "../BankModule/BankModals/UploadModal";
import LabelWithIcon from "../../../common/ui/LabelWithIcon";
import { ReactComponent as AssetIcon } from '../../../common/icons/policy/assets-sm.svg';
import { ReactComponent as HealthIcon } from '../../../common/icons/policy/health-sm.svg';
import { ReactComponent as LifeIcon } from '../../../common/icons/policy/life-sm.svg';
import PolicyList from "./PolicyList";
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
import 'firebase/analytics';
import RemovePolicyModal from "./AddPolicyModals/RemovePolicyModal";


const StepOneScreen = () => {

    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [addPolicy, setAddPolicy] = useState(false);
    const [addPolicyViaNumber, setAddPolicyViaNumber] = useState(false);
    const [remove, setRemove] = useState<any>(false);
    const newManualPolicies = useSelector(selectNewManualPolicies);
    const existingPoliciesFromEIA = useSelector(selectExistingPoliciesFromEIA);
    const isLoadingExistingPolicies = useSelector(isLoadingExistingPoliciesFromEIA);
    const LoadingInsSamadhanUploadDoc = useSelector(isLoadingInsSamadhanUploadDoc);
    const isFirstTimeLogin = location?.state?.isFirstTimeLogin;


    const policyList = useMemo(() => {
        const groupedPolicies = groupPoliciesByALCode(existingPoliciesFromEIA)
        return [
            { code: "L", list: groupedPolicies.L || [] }, { code: "H", list: groupedPolicies.H || [] }, { code: "G", list: groupedPolicies.G || [] }
        ];
    }, [existingPoliciesFromEIA]);


    const ClickAddPolicyViaNumber = () => {
        setAddPolicy(false);
        setAddPolicyViaNumber(true);
    }

    const submitPolicies = (policies: any) => {
        dispatch(addManualPolicies(policies))
    }


    const checkIsEmpty = useMemo(() => {
        const lifePolicies = newManualPolicies.find((val: any) => val.code === 'L')?.list?.length === 0;
        const healthPolicies = newManualPolicies.find((val: any) => val.code === 'H')?.list?.length === 0;
        const motorPolicies = newManualPolicies.find((val: any) => val.code === 'G')?.list?.length === 0;

        return lifePolicies && healthPolicies && motorPolicies;
    }, [newManualPolicies])


    useEffect(() => {
        if (!existingPoliciesFromEIA.length) {
            dispatch(requestExistingPolicies());
        }
    }, [])


    const removeFunction = (policyDetails: any) => {
        dispatch(removePolicy(policyDetails));
        setRemove(false);
    }



    const life = [

        { policyName: "Policy Name Policy Name", policyNo: "12123123123123123", lifeAssured: "34234344", insurerLogo: "https://policygenie2.blob.core.windows.net/insurerlogo/icici_lombard_general_ins.jpg" },
        { policyName: "Policy Name Policy Name", policyNo: "12123121231233123", lifeAssured: "34234344", insurerLogo: "https://policygenie2.blob.core.windows.net/insurerlogo/icici_lombard_general_ins.jpg" },
        // { policyName: "Policy Name Policy Name", policyNo: "12123123222123123123", lifeAssured: "34234344", insurerLogo: "../../../assets/images/HDFC.png" },
        // { policyName: "Policy Name Policy Name", policyNo: "1212312223123123123", lifeAssured: "34234344", insurerLogo: "../../../assets/images/HDFC.png" }
    ]
    const motor = [

        { policyName: "Policy Name Policy Name", policyNo: "12123123123123123", Car: "MH 00 XX 1234", insurerLogo: "https://policygenie2.blob.core.windows.net/insurerlogo/icici_lombard_general_ins.jpg" },
        // { policyName: "Policy Name Policy Name", policyNo: "12123121231233123", lifeAssured: "34234344", insurerLogo: "../../../assets/images/HDFC.png" },
        // { policyName: "Policy Name Policy Name", policyNo: "12123123222123123123", lifeAssured: "34234344", insurerLogo: "../../../assets/images/HDFC.png" },
        // { policyName: "Policy Name Policy Name", policyNo: "1212312223123123123", lifeAssured: "34234344", insurerLogo: "../../../assets/images/HDFC.png" }
    ]
    const health: any = [

        // { policyName: "Policy Name Policy Name", policyNo: "345345345345", lifeAssured: "34234344", insurerLogo: "../../../assets/images/HDFC.png" },
        // { policyName: "Policy Name Policy Name", policyNo: "678456456456", lifeAssured: "34234344", insurerLogo: "../../../assets/images/HDFC.png" },
        // { policyName: "Policy Name Policy Name", policyNo: "834345645656", lifeAssured: "34234344", insurerLogo: "../../../assets/images/HDFC.png" },
        // { policyName: "Policy Name Policy Name", policyNo: "823467567787", lifeAssured: "34234344", insurerLogo: "../../../assets/images/HDFC.png" }
    ]

    const list = [{ code: "L", list: life }, { code: "G", list: motor }, { code: "H", list: health }]

    useEffect(() => {
		const analytics = getAnalytics();
		logEvent(analytics, 'page_view', {
			page_location: window.location.href,
			page_path: location.pathname,
			page_title: 'Onboard Policy Step One Screen',
		});
	}, []);



    return (
        <>
            <Grid container justifyContent="center">
                <Box width={414} >

                    {isLoadingExistingPolicies ? (
                        <Grid container justifyContent="center" >
                            <Spinner />
                        </Grid>
                    ) :
                        (

                            <Grid className="m-50" width={350}>

                                <Grid item className="pt-30" sx={{
                                    position: "fixed",
                                    backgroundColor: '#ffffff',
                                    top: "0px",
                                    width: 350,
                                    zIndex: 1
                                }}>

                                    <Grid item className="mb-15" container justifyContent={'space-between'} >
                                        <Typography
                                            className="f-16 fw-600"
                                            color={theme.palette.primary.main}
                                        >
                                            {t('ADD_POLICIES')}
                                        </Typography>
                                        <CustomButton
                                            text={t('CANCEL_EXIT')}
                                            variant="text"
                                            color='inherit'
                                            className="fw-400 f-16 "
                                            showIcon={false}
                                            textAlign="end"
                                            onClick={() => { isFirstTimeLogin ? navigate(APP_ROUTES.DASHBOARD) : navigate(APP_ROUTES.POLICY) }}
                                        />
                                    </Grid>


                                    <Grid container justifyContent="center" >
                                        <Separator color={theme.palette.grey[400]} borderWidth={1} />
                                    </Grid>
                                </Grid>
                                <Box style={{ backgroundColor: theme.palette.grey[100] }}>
                                    <Grid className="p-10">
                                        <PolicyListCard headingName={t('CURRENT_POLICY')} policyList={policyList} />
                                    </Grid>
                                    <Grid container justifyContent="center" >
                                        <Separator color={theme.palette.grey[400]} borderWidth={1} />
                                    </Grid>


                                </Box>


                                <Grid container className="mt-20">
                                    <Grid item xs={11} container justifyContent={'space-between'} alignItems={'center'} className="mt-40 mb-10">
                                        <Grid item>
                                            <LabelWithIcon Icon={LifeIcon} label={t('LIFE')} labelProps={{ fontSize: 20, fontWeight: 400, color: theme.palette.primary.main }} /> </Grid>
                                        <Grid item>
                                            <Typography
                                                className="f-18 fw-400"
                                                color={theme.palette.secondary.main}
                                            >
                                                &
                                            </Typography> </Grid>
                                        <Grid item>
                                            <LabelWithIcon Icon={HealthIcon} label={t('HEALTH')} labelProps={{ fontSize: 20, fontWeight: 400, color: theme.palette.primary.main }} /> </Grid>


                                    </Grid>

                                    <Grid container justifyContent="center" >
                                        <Separator color={theme.palette.grey[400]} borderWidth={1} />
                                    </Grid>
                                    {newManualPolicies.filter((item: any) => item.code === 'L')[0].list.length || newManualPolicies.filter((item: any) => item.code === 'H')[0].list.length ?
                                        <><PolicyList title={t('LIFE_INSURANCE')} insurerCode={'L'} list={newManualPolicies.filter((item: any) => item.code === 'L')[0].list} showRemove={true} removePolicyFunc={(data: any) => setRemove(data)} />
                                            <PolicyList title={t('HEALTH_INSURANCE')} insurerCode={'H'} list={newManualPolicies.filter((item: any) => item.code === 'H')[0].list} showRemove={true} removePolicyFunc={(data: any) => setRemove(data)} /></> : (<Typography
                                                className="f-14 fw-400 mb-30 mt-10"
                                                color={theme.palette.grey[300]}
                                            >

                                                {t('NO_POLICIES')}
                                            </Typography>)}

                                    <Grid container justifyContent="center" >
                                        <Separator color={theme.palette.grey[400]} borderWidth={1} />
                                    </Grid>
                                    <Grid container xs={12} alignItems={'center'}>
                                        <Grid xs={7} item className="my-10">

                                        </Grid>
                                        <Grid xs={5} item className="my-10">
                                            <CustomButton
                                                text={t('ADD_POLICIES')}
                                                variant="text"
                                                color="primary"
                                                className="fw-600 f-16 "
                                                showIcon
                                                textAlign="end"
                                                onClick={() => setAddPolicy(true)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={8} container justifyContent={'space-between'} alignItems={'center'} className="mt-40 mb-10">
                                        <Grid item>
                                            <LabelWithIcon Icon={AssetIcon} label={t('Assets (Motor)')} labelProps={{ fontSize: 20, fontWeight: 400, color: theme.palette.primary.main }} /> </Grid>
                                    </Grid>

                                    <Grid container justifyContent="center" >
                                        <Separator color={theme.palette.grey[400]} borderWidth={1} />
                                    </Grid>
                                    {newManualPolicies.filter((item: any) => item.code === 'G')[0].list.length ? <PolicyList title={t('MOTOR_INSURANCE')} insurerCode={'G'} showRemove={true} removePolicyFunc={(data: any) => setRemove(data)} list={newManualPolicies.filter((item: any) => item.code === 'G')[0].list} /> : (<Typography
                                        className="f-14 fw-400 mb-30 mt-10"
                                        color={theme.palette.grey[300]}
                                    >

                                        {t('NO_POLICIES')}
                                    </Typography>)}
                                </Grid>
                                <Grid container justifyContent="center" >
                                    <Separator color={theme.palette.grey[400]} borderWidth={1} />
                                </Grid>
                                <Grid container xs={12} alignItems={'center'}>
                                    <Grid xs={7} item className="my-10">

                                    </Grid>
                                    <Grid xs={5} item className="my-10">
                                        <CustomButton
                                            text={t('ADD_POLICIES')}
                                            variant="text"
                                            color="primary"
                                            className="fw-600 f-16 "
                                            showIcon
                                            textAlign="end"
                                            onClick={() => setAddPolicyViaNumber(true)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid style={{ opacity: '0.5', pointerEvents: 'none', display: 'none' }}>
                                    <Grid display="flex">
                                        <Grid className="mt-6">
                                            <SvgConverter Icon={Arrow} />
                                        </Grid>
                                        <Typography
                                            sx={{ fontSize: 18, fontStyle: 'oblique' }}
                                            color="text.secondary"
                                            gutterBottom
                                            className="ml-10 mt-1 fw-400"
                                        >
                                            {t('FINISH_STEP1')}
                                        </Typography>

                                    </Grid>
                                    <Typography
                                        color={theme.palette.grey[500]}
                                        className="mt-1 f-12 fw-400"
                                    >
                                        {t('FINISH_STEP1_INSTRUCTION')}
                                    </Typography>
                                    <Grid container xs={12}>
                                        <Grid xs={5} />
                                        <Grid xs={7} item className="my-20">
                                            <Link to={APP_ROUTES.ADD_POLICY_STEP_TWO_INSTRUCTION}>
                                                <CustomButton
                                                    text={t('CONTINUE_TO_NEXT_STEP')}
                                                    variant="text"
                                                    color="inherit"
                                                    className="fw-600 f-16 "
                                                    showIcon
                                                    textAlign="end"
                                                />
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid display="flex">
                                    <Grid className="mt-6">
                                        <SvgConverter Icon={Arrow} />
                                    </Grid>
                                    <Typography
                                        sx={{ fontSize: 18, fontStyle: 'oblique' }}
                                        color="text.secondary"
                                        gutterBottom
                                        className="ml-10 mt-1 fw-400"
                                    >
                                        {t('FINISHED_ADDING')}
                                    </Typography>

                                </Grid>
                                <Grid container xs={12}>
                                    <Grid xs={5} />
                                    <Grid xs={7} item className="my-20">
                                        <Link to={APP_ROUTES.ADD_POLICY_SUBMITTION_PREVIEW} state={{ isFirstTimeLogin: isFirstTimeLogin }} style={{ color: theme.palette.common.black }} className={checkIsEmpty ? 'disabled-link' : ''}>
                                            <CustomButton
                                                text={t('FINISH_POLICY_ONBOARDING')}
                                                variant="text"
                                                color="inherit"
                                                className="fw-600 f-16 "
                                                showIcon
                                                textAlign="end"
                                                disabled={checkIsEmpty}
                                            />
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>)

                    }

                </Box>
            </Grid>


            <AddPolicyModal open={addPolicy} setOpen={setAddPolicy} ClickAddPolicyViaNumber={ClickAddPolicyViaNumber} />


            <AddPolicyViaNumberModal open={addPolicyViaNumber} setOpen={setAddPolicyViaNumber} submitPolicies={submitPolicies} />

            <UploadModal open={LoadingInsSamadhanUploadDoc} />
            <ApiModal />

            <RemovePolicyModal open={remove} setOpen={setRemove} submit={() => removeFunction(remove)} />
        </>
    );
}

export default StepOneScreen;