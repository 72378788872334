import { useTranslation } from "react-i18next";
import PolicyListCard from "./PolicyListCard";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import Separator from "../../../common/ui/Separator";
import FormCheckBox from "../../../common/ui/Forms/FormInput/CheckBox/FormCheckBox";
import { useEffect, useState } from "react";
import CustomButton from "../../../common/ui/CustomButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../../common/constants/Routes";
import { useDispatch, useSelector } from "react-redux";
import { resetExistingPolicies, selectNewManualPolicies } from "./addPolicy.slice";
import { addNewPolicy, addPolicyLoading } from "../PolicyScreen/Policies.slice";
import { TermsCondtionsModal } from "../AddPolicyScreen/AddPolicyModals/TermsConditionsModal";

const SubmissionPreviewScreen = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const newManualPolicies = useSelector(selectNewManualPolicies);
    const addPolicyloading = useSelector(addPolicyLoading)
    const [open, setOpen] = useState(false);
    const lifePolicies = newManualPolicies.find((val: any) => val.code === 'L')?.list;
    const healthPolicies = newManualPolicies.find((val: any) => val.code === 'H')?.list;
    const motorPolicies = newManualPolicies.find((val: any) => val.code === 'G')?.list;
    const isFirstTimeLogin = location?.state?.isFirstTimeLogin;



    const previewSubmit = () => {



        const data = [...lifePolicies, ...healthPolicies, ...motorPolicies]
        console.log(data)

        const handleSuccess = () => {
            navigate(isFirstTimeLogin ? APP_ROUTES.DASHBOARD : APP_ROUTES.POLICY)
            dispatch(resetExistingPolicies())
        }

        dispatch(
            addNewPolicy({
                data,
                navigate,
                handleSuccess
            } as any)
        );
    }


    useEffect(() => {
        if (!lifePolicies.length && !healthPolicies.length && !motorPolicies.length) {
            navigate(APP_ROUTES.POLICY);
        }
    }, [lifePolicies, healthPolicies, motorPolicies])

    const [isChecked, setIsChecked] = useState(false);

    return (<>
        <Grid container justifyContent="center">
            <Box width={414} >
                <Grid className="m-50" width={295}>

                    <Grid item className="mb-15" style={{ position: 'sticky' }} container justifyContent={'space-between'} >
                        <Typography
                            className="f-16 fw-600"
                            color={theme.palette.primary.main}
                        >
                            {t('SUBMISSION_PREVIEW')}
                        </Typography>
                        <CustomButton
                            text={t('CANCEL_EXIT')}
                            variant="text"
                            color='inherit'
                            className="fw-400 f-16 "
                            showIcon={false}
                            textAlign="end"
                            onClick={() => navigate(isFirstTimeLogin ? APP_ROUTES.DASHBOARD : APP_ROUTES.POLICY)}
                        />
                    </Grid>
                    <Grid >
                        <Grid container justifyContent="center" >
                            <Separator color={theme.palette.grey[400]} borderWidth={1} />
                        </Grid>
                        <Grid className="my-20">
                            <PolicyListCard headingName={t('SUBMISSION_POLICY')} policyList={newManualPolicies} />
                        </Grid>

                        <Grid className="mt-50">


                            <FormCheckBox
                                label={
                                    <>
                                        <Grid container alignItems={'center'} onClick={(e: any) => { e.stopPropagation() }}>
                                            <Typography
                                                className="f-13 fw-400"
                                            >
                                                &nbsp;{t('AGREE_TO_THE')}&nbsp;
                                            </Typography>
                                            <Typography
                                                className="f-14 fw-600"
                                                style={{ color: '#0000FF'}}
                                                onClick={() => setOpen(true)}
                                            >
                                                {t('TERMS_&_CONDITIONS')}
                                            </Typography>
                                        </Grid>
                                    </>
                                }
                                checked={isChecked}
                                onChange={() => { isChecked && !open ? setIsChecked(false) : setIsChecked(true) }}
                                size={16}
                                checkBoxPadding={1}
                            />
                        </Grid>

                        <Grid
                            container
                            justifyContent="space-between"
                            // spacing={1}
                            className="mt-40"
                        >

                            <Grid item xs={5}>
                                <Link to={APP_ROUTES.ADD_POLICY_STEP_ONE} >
                                    <Typography
                                        className="f-16 fw-400"
                                        color={theme.palette.common.black}
                                    >
                                        {t('ADD_MORE_POLICIES')}
                                    </Typography>
                                </Link>
                            </Grid>
                            <Grid item>
                                <CustomButton
                                    text={t('SUBMIT')}
                                    variant="text"
                                    showIcon={true}
                                    className="f-16 fw-600"
                                    disabled={!isChecked}
                                    loading={addPolicyloading}
                                    type="submit"
                                    onClick={previewSubmit}
                                />
                            </Grid>
                        </Grid>
                    </Grid>


                </Grid>
            </Box>

            <TermsCondtionsModal open={open} setOpen={setOpen} />
        </Grid>
    </>);
}


export default SubmissionPreviewScreen;